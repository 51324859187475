<template>
    <div class="vouchersTable resumePayment">
        <div class="vouchersBlock">
            <div class="vouchersTableRow">
                <div class="label">Totale</div>
                <div></div>
                <div class="value">
                    {{etcAmount}} €
                    <span
                        class="danger"
                        v-if="etcAmount > checkoutAmount"
                        v-b-popover.hover.top="'Il valore dei Ticket Compliments &reg; ha superato il valore del costo da pagare. I Ticket Compliments &reg; non possono essere usati in modo parziale. Rimuovi un Ticket e paga la differenza con carta di credito.'">
                        Attenzione! hai superato il costo totale <SvgInfoCircle class="svgInfo" />
                    </span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import SvgInfoCircle from '../../svg/info-circle'
export default {
    name: 'sidebar-etc-resume-payment',
    props:
    {
        etcAmount: Number,
        checkoutAmount: Number,
    },
    components:
    {
        SvgInfoCircle
    }
}
</script>

<style lang="scss">
    
    
    

    .resumePayment
    {
        .vouchersTableRow
        {
            border-bottom:0 !important;
        }
        *
        {
            text-align:center;
        }
        /*
        display:flex;
        flex-wrap:wrap;
        margin-bottom:0.75rem !important;
        margin-top:0.15rem;
        text-align:center;
        opacity:1;
        transition:$transition-base;
        .label
        {
            flex:66%;
            width:66%;
            min-width:66%;
        }
        .value
        {
            flex:33%;
            width:33%;
            min-width:33%;
        }
        */
    }
</style>