<template>
    <div id="sidebar">
        <div class="sidebar-inner">

            <div id="sidebar-etc">

                <b-row >
                    <b-col sm="12">
                        <router-link class="nounderline" :to="{ name: 'property', params: { propertyId: bookingData.hotelId, propertyName: this.bookingData.hotelName }}">
                            <BtnBack :text="'Torna indietro'" />
                        </router-link>
                        <br>
                    </b-col>
                </b-row>

                <!-- credito welfare non sufficiente -->
                <div v-if="this.$route.name == 'checkout' && number(availability) > 0 && larger(number(bookingData.live_price), number(availability))">
                    <b-alert variant="danger" class="text-center" show><b>Credito welfare non sufficiente!</b></b-alert>
                    <div class="panel-tertiary">
                        <div class="top">
                            <img :src="avatarSrc" />
                            <div>
                                <p class="name">{{displayName}}</p>
                                <p v-if="availability > 0" class="credit">Credito Welfare € {{availability}}</p>
                            </div>
                        </div>

                        <div class="panel-content">
                            <h5 class="panel-title">Riepilogo</h5>
                            
                            <div class="checkout-resume-block">
                                <div class="checkout-resume-row align-left mb-2">
                                  <b>{{bookingData.roomDescription}}</b>
                                </div>
                                <div class="checkout-resume-row align-left pl-3 mb-2">
                                  <div>
                                    {{bookingData.numberOfAdults}} <span v-if="bookingData.numberOfAdults > 1">adulti</span><span v-else>adulto</span>
                                    <span v-if="bookingData.childs.length">, {{bookingData.childs.length}} <span v-if="bookingData.childs.length > 1">bambini</span><span v-else>bambino</span>
                                    (<span v-for="(c,i) in bookingData.childs" v-bind:key="_uid+i">{{ c }}<span v-if="i<(bookingData.childs.length-1)">, </span></span> anni)</span>
                                  </div>
                                </div>
                                <div class="checkout-resume-row pl-4">
                                    <span>{{bookingData.nights}} <span v-if="bookingData.nights > 1">Notti</span><span v-else>Notte</span></span>
                                    <span>
                                        {{bookingData.totals.exclusive}} €
                                    </span>
                                </div>
                                <div class="checkout-resume-row pl-4">
                                    <span
                                        v-b-popover.hover.top="'Questo addebito include gli importi stimati che il fornitore dei servizi di viaggio (ad esempio hotel, società di autonoleggio) paga in forma di tasse e/o le tasse che paghiamo alle autorità fiscali sulla tua prenotazione (incluse, a titolo esemplificativo, le imposte sulle vendite, di soggiorno o sul valore aggiunto). Può anche includere eventuali importi a noi addebitati per le tariffe resort, i supplementi per le pulizie e altre spese e/o un costo che noi, il fornitore dell\'hotel e/o il sito su cui hai prenotato tratteniamo come parte del compenso per i nostri servizi, che varia in base a fattori quali la posizione, l\'importo e la modalità di prenotazione. Per maggiori informazioni, consulta i termini e le condizioni.'"
                                        id="info-taxes" class="cursor-pointer">Tasse ed oneri <SvgInfoCircle />
                                    </span>
                                    <span>
                                        {{mixinRoundTwoDigits(bookingData.totals.inclusive - bookingData.totals.exclusive)*100/100}} €
                                    </span>
                                </div>
                                <div class="checkout-resume-row pl-3">
                                    <span v-b-popover.hover.top="'Costi di gestione della tua prenotazione e per l\'assistenza multicanale e dedicata ' + site.brand + '.'" id="info-taxes" class="cursor-pointer">{{ site.brand }} service charge <SvgInfoCircle />
                                    </span>
                                    <span>
                                        {{mixinRoundTwoDigits(bookingData.live_price - bookingData.totals.inclusive)*100/100}} €
                                    </span>
                                </div>
                            </div>

                            <div class="checkout-resume-block">
                                <div class="checkout-resume-row">
                                    <strong>Totale per il viaggio</strong>
                                    <strong>{{mixinRoundTwoDigits(bookingData.live_price)}} €</strong>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <small>Totalmente saldato al momento della prenotazione</small>
                                </div>
                            </div>

                            <div class="checkout-resume-block" v-if="this.$route.name == 'checkout'">
                                <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_tax > 0">
                                    <span v-b-popover.hover.top="'Il costo della tassa di soggiorno viene definito dall\'Amministrazione Comunale, deve essere pagato in struttura ed è soggetto a variazioni. Il costo che vedi sul nostro sito è stato inserito dalla struttura alberghiera e non possiamo garantire che sia stato inserito correttamente e che sia aggiornato. Se desideri informazioni definitive ti invitiamo a consultare il sito dell\'Amministrazione Comunale o a contattare la struttura alberghiera.'">
                                      Tassa di soggiorno
                                      <SvgInfoCircle />
                                    </span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_tax)}} €</span>
                                </div>
                                <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_fee > 0">
                                    <span>Spese di pulizia ed utenze</span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_fee)}} €</span>
                                </div>
                                <div class="checkout-resume-row" v-if="bookingData.totals.resort_fee > 0">
                                    <span>Resort fee</span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.resort_fee)}} €</span>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <small>Pagabili in hotel al check-in o al check-out</small>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- credito welfare sufficiente -->
                <div v-else>
                    <div class="sidebar-checkout-panel">

                        <!-- credito welfare/info utente -->
                        <div class="panel-content top">
                            <img :src="avatarSrc" />
                            <div>
                                <p class="name">{{displayName}}</p>
                                <p v-if="availability > 0" class="credit">Credito Welfare € {{availability}}</p>
                            </div>
                        </div>

                        <!-- Riepilogo dei costi -->
                        <div class="panel-content">
                            <h5 class="panel-title">Riepilogo dei costi</h5>
                            <div class="checkout-resume-block pb-0">
                                <div class="checkout-resume-row align-left mb-2">
                                  <b>{{bookingData.roomDescription}}</b>
                                </div>
                                <div class="checkout-resume-row align-left pl-3 mb-2">
                                  <div>
                                    {{bookingData.numberOfAdults}} <span v-if="bookingData.numberOfAdults > 1">adulti</span><span v-else>adulto</span>
                                    <span v-if="bookingData.childs.length">, {{bookingData.childs.length}} <span v-if="bookingData.childs.length > 1">bambini</span><span v-else>bambino</span>
                                    (<span v-for="(c,i) in bookingData.childs" v-bind:key="_uid+i">{{ c }}<span v-if="i<(bookingData.childs.length-1)">, </span></span> anni)</span>
                                  </div>
                                </div>
                                <div class="checkout-resume-row pl-3">
                                    <span>{{bookingData.nights}} <span v-if="bookingData.nights > 1">Notti</span><span v-else>Notte</span></span>
                                    <span>
                                        {{bookingData.totals.exclusive}} €
                                    </span>
                                </div>
                                <div class="checkout-resume-row pl-3">
                                    <span
                                        v-b-popover.hover.top="'Questo addebito include gli importi stimati che il fornitore dei servizi di viaggio (ad esempio hotel, società di autonoleggio) paga in forma di tasse e/o le tasse che paghiamo alle autorità fiscali sulla tua prenotazione (incluse, a titolo esemplificativo, le imposte sulle vendite, di soggiorno o sul valore aggiunto). Può anche includere eventuali importi a noi addebitati per le tariffe resort, i supplementi per le pulizie e altre spese e/o un costo che noi, il fornitore dell\'hotel e/o il sito su cui hai prenotato tratteniamo come parte del compenso per i nostri servizi, che varia in base a fattori quali la posizione, l\'importo e la modalità di prenotazione. Per maggiori informazioni, consulta i termini e le condizioni.'"
                                        id="info-taxes" class="cursor-pointer">Tasse ed oneri <SvgInfoCircle />
                                    </span>
                                    <span>
                                        {{mixinRoundTwoDigits(bookingData.totals.inclusive - bookingData.totals.exclusive)*100/100}} €
                                    </span>
                                </div>
                                <div class="checkout-resume-row pl-3">
                                    <span v-b-popover.hover.top="'Costi di gestione della tua prenotazione e per l\'assistenza multicanale e dedicata ' + site.brand + '.'" id="info-taxes" class="cursor-pointer">{{ site.brand }} service charge <SvgInfoCircle />
                                    </span>
                                    <span>
                                        {{mixinRoundTwoDigits(bookingData.live_price - bookingData.totals.inclusive)*100/100}} €
                                    </span>
                                </div>
                            </div>

                            <div class="checkout-resume-block pb-0">
                                <div class="checkout-resume-row">
                                    <strong>Totale per il viaggio</strong>
                                    <strong>{{mixinRoundTwoDigits(bookingData.live_price)}} €</strong>
                                </div>

                                <div class="d-flex justify-content-center">
                                    <small>Totalmente saldato al momento della prenotazione</small>
                                </div>
                            </div>

                            <div class="checkout-resume-block" v-if="this.$route.name == 'checkout' && fees != 0">
                                <strong>Costi obbligatori non inclusi</strong>
                                <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_tax !== 0">
                                    <span v-b-popover.hover.top="'Il costo della tassa di soggiorno viene definito dall\'Amministrazione Comunale, deve essere pagato in struttura ed è soggetto a variazioni. Il costo che vedi sul nostro sito è stato inserito dalla struttura alberghiera e non possiamo garantire che sia stato inserito correttamente e che sia aggiornato. Se desideri informazioni definitive ti invitiamo a consultare il sito dell\'Amministrazione Comunale o a contattare la struttura alberghiera.'">
                                      Tassa di soggiorno
                                      <SvgInfoCircle />
                                    </span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_tax)}} €</span>
                                </div>
                                <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_fee !== 0">
                                    <span>Spese di pulizia ed utenze</span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_fee)}} €</span>
                                </div>
                                <div class="checkout-resume-row" v-if="bookingData.totals.resort_fee !== 0">
                                    <span>Resort fee</span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.resort_fee)}} €</span>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <small>Da pagare in struttura al check-in</small>
                                </div>
                            </div>
                            <div class="sidebarResumeBlock" v-else-if="this.$route.name == 'checkout'">

                              <strong>Costi obbligatori non inclusi</strong>
                              &nbsp;
                              <div class="d-flex justify-content-center">
                                La struttura non ha comunicato altri costi obbligatori da pagare in loco. Controlla anche il capitolo Informazioni importanti che trovi in questa pagina oppure contattaci per maggiori informazioni.
                              </div>

                            </div>
                        </div>

                        <!-- dati del viaggiatore -->
                        <div class="panel-content">
                            <h5 class="panel-title" v-b-toggle.checkoutStatusUserData>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">1</span> Dati del viaggiatore
                                </span>
                                <SvgChevronDown class="chevron" />
                            </h5>
                            <b-collapse id="checkoutStatusUserData" v-model="checkoutStatusUserDataVisible" accordion="checkoutStatus">
                                <div class="checkout-resume-block">

                                    <!-- SIDEBAR REFACTOR: sub component della sidebar, in futuro saranno tutti così; potenzialmente anche il tasto Conferma di ciascun cassetto potrebbe essere gestito a livello di sub component -->
                                    <CheckoutPax
                                        inputSize="lg"
                                        v-if="sidebarBlocks.checkoutPax.initialize"
                                        v-on:sidebarBlockChanged="sidebarBlockChange"
                                    />

                                    <!-- SIDEBAR REFACTOR: sub component della sidebar, in futuro saranno tutti così; potenzialmente anche il tasto Conferma di ciascun cassetto potrebbe essere gestito a livello di sub component -->
                                    <CheckoutBillToPax
                                        inputSize="lg"
                                        v-if="sidebarBlocks.checkoutBillToPax.initialize"
                                        v-on:sidebarBlockChanged="sidebarBlockChange"
                                    />

                                    <div class="checkoutStatusButtons mt-4">
                                        <b-button :disabled="!getUserDataStatus()" variant="success" v-b-toggle.checkoutStatusPaymentMethods>Conferma</b-button>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>

                        <!-- Strumenti di pagamento -->
                        <div class="panel-content">
                            <h5 class="panel-title" v-if="getUserDataStatus()" v-b-toggle.checkoutStatusPaymentMethods>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">2</span>
                                    Usa i tuoi Ticket Compliments &reg;
                                </span>
                                <SvgChevronDown class="chevron" />
                            </h5>
                            <h5 class="panel-title" v-else>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">2</span>
                                    Usa i tuoi Ticket Compliments &reg;
                                </span>
                            </h5>
                            <b-collapse id="checkoutStatusPaymentMethods" v-model="checkoutStatusPaymentMethodsVisible" accordion="checkoutStatus">
                                <div class="checkout-resume-block">
                                    <div class="checkout-resume-row">
                                        <div role="tablist">

                                            <span class="togglePayment" v-b-toggle.accordion-etc>
                                                <span class="d-flex align-items-center"><TicketIcon /> Usa i tuoi Ticket Compliments &reg;</span><SvgChevronDown class="chevron" />
                                            </span>

                                            <!-- Blocco ETC -->
                                            <div v-if="!loadingEtc">
                                                <b-collapse id="accordion-etc" accordion="payments" role="tabpanel" v-model="etcCollapseOpen">
                                                    <div :class="canUseEtc ? '' : 'cannotUseEtc'">
                                                        
                                                        <div class="paymentPartnerBlock">
                                                            <div class="icon logout">
                                                                <img src="assets/edenred-logo.svg" />
                                                            </div>
                                                            <div class="name etcBlue">
                                                                <div>
                                                                    Accesso a Edenred effettuato
                                                                    <br>
                                                                    <span @click="mixinLogout()" class="logout etcRed">esci</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-if="!canUseEtc" class="cannotUseEtcAlert">
                                                            <span class="danger">
                                                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16.878 11.3112C15.3892 8.73332 12.9524 4.5122 11.4636 1.93472C9.97497 -0.644906 7.5382 -0.644906 6.04921 1.93472C4.56129 4.5122 2.12435 8.73332 0.635359 11.3112C-0.853271 13.8904 0.364487 16 3.34264 16C6.32044 16 11.1929 16 14.1703 16C17.1481 16 18.3668 13.8904 16.878 11.3112ZM8.68462 13.1522C7.9226 13.1522 7.30577 12.5348 7.30577 11.773C7.30577 11.011 7.9226 10.3934 8.68462 10.3934C9.44681 10.3934 10.064 11.011 10.064 11.773C10.064 12.5346 9.44681 13.1522 8.68462 13.1522ZM10.1151 8.12954C10.1151 8.91926 9.47435 9.56024 8.68462 9.56024C7.89507 9.56024 7.25445 8.91926 7.25445 8.12954V5.67775C7.25445 4.88784 7.89507 4.24723 8.68462 4.24723C9.47435 4.24723 10.1151 4.88784 10.1151 5.67775V8.12954Z" fill="#FF0000"/>
                                                                </svg>
                                                                non disponibile
                                                            </span>
                                                            <p>
                                                                Non disponi di Ticket Compliments &reg; oppure sono di importo superiore al costo della prenotazione (non sono possibili resti). Puoi comunque prenotare saldando per intero tramite carta di credito
                                                            </p>
                                                        </div>

                                                        <div class="vouchersTable" v-else>
                                                            <div class="vouchersBlock">
                                                                <div class="vouchersTableRow">
                                                                    <div><strong>BUONO</strong></div>
                                                                    <div><strong>QUANTITÀ</strong></div>
                                                                    <div><strong>TOTALE</strong></div>
                                                                </div>
                                                            </div>
                                                            <div class="vouchersBlock">
                                                                <div v-for="(voucher,index) in (vouchers)" v-bind:key="index">
                                                                    <div class="vouchersTableRow">
                                                                        <div class="voucherValue">{{voucher.value}} €</div>
                                                                        <div class="voucherCount">
                                                                            <span @click="removeVoucher(index)" class="setVoucherIcon minusOk" v-if="voucher.used > 0">
                                                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="7.5" cy="7.5" r="7.5" fill="#0071BC"/>
                                                                                    <path d="M5.85141 8.04H9.15141V9.288H5.85141V8.04Z" fill="#E9EBF0"/>
                                                                                </svg>
                                                                            </span>
                                                                            <span class="setVoucherIcon minusKo" v-else>
                                                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="7.5" cy="7.5" r="7.5" fill="#EAEBF0"/>
                                                                                    <path d="M5.85141 8.04H9.15141V9.288H5.85141V8.04Z" fill="#ffffff"/>
                                                                                </svg>
                                                                            </span>
                                                                            <span v-bind="calculating">
                                                                                {{string(round(number(voucher.used), 2))}}
                                                                            </span>
                                                                            <span @click="addVoucher(index)" class="setVoucherIcon plusOk" v-if="smaller(number(voucher.used), number(voucher.count))">
                                                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="7.5" cy="7.5" r="7.5" fill="#0071BC"/>
                                                                                    <path d="M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z" fill="#E9EBF0"/>
                                                                                </svg>
                                                                            </span>
                                                                            <span class="setVoucherIcon plusKo" v-else>
                                                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="7.5" cy="7.5" r="7.5" fill="#EAEBF0"/>
                                                                                    <path d="M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z" fill="#ffffff"/>
                                                                                </svg>
                                                                            </span>                                
                                                                        </div>
                                                                        <div class="voucherTotal">{{string(round(number(voucher.amount), 2))}} €</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ResumePaymentEtc :etcAmount="etcAmount" :checkoutAmount="checkoutAmount"/>

                                                        <div class="etcLower">
                                                            <div class="etcAutoSelect" v-if="canUseEtc">
                                                                <p>Usa il selettore automatico</p>
                                                                <CustomSwitch v-bind="calculating" :status="etcAutoSelect" :switchUniqueKey="'etc-auto-select'" v-on:switch-change="etcAutoSelectCalculation"/>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </b-collapse>
                                                <ResumePaymentEtc :etcAmount="etcAmount" :checkoutAmount="checkoutAmount"/>
                                            </div>
                                            <div v-else><Spinner /></div>

                                            <span class="togglePayment black">
                                                <span class="d-flex align-items-center"><SvgCreditCard /> Da pagare con carta di credito</span>
                                            </span>
                                            <p class="resumePayment">
                                                <span class="label">Addebito su carta di credito </span>
                                                <span class="value">{{creditCardAmount}} €</span>
                                            </p>

                                            <!--
                                            <span class="togglePayment" v-b-toggle.accordion-cc>
                                                <span class="d-flex align-items-center"><SvgCreditCard /> Paga con carta di credito</span> <SvgChevronDown class="chevron" />
                                            </span>
                                            -->
                                            
                                            <!-- Blocco CC
                                            <div>
                                                <b-collapse id="accordion-cc" accordion="payments" role="tabpanel" v-model="ccCollapseOpen">
                                                    <div>
                                                        <Stripe ref="stripe" :amount="creditCardAmount" v-on:stripe-change="stripeChange" v-on:stripe-confirm="stripeConfirm" />
                                                    </div>
                                                </b-collapse>
                                                <p class="resumePayment">
                                                    <span class="label"><b>Addebito su carta di credito</b></span>
                                                <span class="value"><b>{{creditCardAmount}} €</b></span>
                                                </p>
                                            </div>
                                            -->
                                        </div>
                                    </div>
                                    <div class="checkoutStatusButtons">
                                        <!--<b-button v-if="etcAmount == 0" @click="etcReset()" v-b-toggle.accordion-etc>Chiudi</b-button>-->
                                        <b-button :disabled="!getPaymentMethodsStatus()" variant="success" v-b-toggle.checkoutStatusConfirm>CONFERMA LA SELEZIONE</b-button>
                                        <div class="text-center">
                                            <b-button v-if="canUseEtc && etcCollapseOpen" size="sm" class="no-etc" @click="etcReset()" v-b-toggle.accordion-etc>Non usare Ticket Compliments</b-button>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>

                        <!-- Conferma -->
                        <div class="panel-content">
                            <h5 class="panel-title" v-if="getPaymentMethodsStatus()" v-b-toggle.checkoutStatusConfirm>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">3</span>
                                    Conferma
                                </span>
                                <SvgChevronDown class="chevron" />
                            </h5>
                            <h5 class="panel-title" v-else>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">3</span>
                                    Conferma
                                </span>    
                            </h5>
                            <b-collapse id="checkoutStatusConfirm" v-model="checkoutStatusConfirmVisible" accordion="checkoutStatus">

                                <p
                                    class="danger mt-3 mb-3" v-if="canUseEtc && etcAmount == 0"
                                    v-b-popover.hover.top="'Se vuoi usare i tuoi Ticket Compliments &reg; torna alla sezione USA I TUOI TICKET COMPLIMENTS &reg;. Altrimenti prosegui pure a pagare con la tua carta di credito.'"
                                >
                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.878 11.3112C15.3892 8.73332 12.9524 4.5122 11.4636 1.93472C9.97497 -0.644906 7.5382 -0.644906 6.04921 1.93472C4.56129 4.5122 2.12435 8.73332 0.635359 11.3112C-0.853271 13.8904 0.364487 16 3.34264 16C6.32044 16 11.1929 16 14.1703 16C17.1481 16 18.3668 13.8904 16.878 11.3112ZM8.68462 13.1522C7.9226 13.1522 7.30577 12.5348 7.30577 11.773C7.30577 11.011 7.9226 10.3934 8.68462 10.3934C9.44681 10.3934 10.064 11.011 10.064 11.773C10.064 12.5346 9.44681 13.1522 8.68462 13.1522ZM10.1151 8.12954C10.1151 8.91926 9.47435 9.56024 8.68462 9.56024C7.89507 9.56024 7.25445 8.91926 7.25445 8.12954V5.67775C7.25445 4.88784 7.89507 4.24723 8.68462 4.24723C9.47435 4.24723 10.1151 4.88784 10.1151 5.67775V8.12954Z" fill="#FF0000"/>
                                    </svg>
                                    Attenzione, non stai usando i tuoi Ticket Compliments &reg;
                                </p>

                                <div class="checkout-resume-block">

                                    <div class="checkout-resume-row" v-if="creditCardAmount > 0">
                                        <span>Paga con carta di credito</span><strong>{{creditCardAmount}} €</strong>
                                    </div>
                                    <div class="checkout-resume-row mb-0" v-if="creditCardAmount > 0">
                                        <Stripe ref="stripe" :amount="creditCardAmount" v-on:stripe-change="stripeChange" v-on:stripe-confirm="stripeConfirm" />
                                    </div>

                                    <div class="checkout-resume-row mb-0">
                                        <b-form-group class="mb-0" id="checkout-form">
                                            <div class="privacy-box">
                                                <b-form-checkbox v-model="guestPrivacy" ref="checkoutPrivacy" required type="checkbox" name="checkbox" id="checkbox-privacy" />
                                                <label for="checkbox-privacy">
                                                    <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                                                        <span class="cta">Ho preso visione delle condizioni d'uso e le accetto</span>
                                                    </router-link>
                                                </label>
                                            </div>

                                            <div class="privacy-box">
                                                <b-form-checkbox v-model="guestGdpr" ref="checkoutGdpr" required type="checkbox" name="checkbox" id="checkbox-gdpr" />
                                                <label for="checkbox-gdpr">
                                                    <p>
                                                        <a :href="site.urlPrivacy" target="_blank">Ho preso visione delle condizioni di trattamento dei dati e le accetto</a>
                                                    </p>
                                                </label>
                                            </div>

                                            <div class="privacy-box" v-if="disclaimerCheckout !== undefined">
                                                <label for="checkbox-gdpr">
                                                    <!--<p class="checkboxInfo" v-html="disclaimerCheckout"></p>-->
                                                    <p v-html="disclaimerCheckout"></p>
                                                </label>
                                            </div>


                                            <!--<b-button v-if="!loading" class="btn btn-block" :disabled="!canFinallyCheckout()" @click="getIntentClientSecret">PRENOTA!</b-button>-->

                                          <!--<b-button v-if="!loading" class="btn btn-block btn-success" :disabled="!canFinallyCheckout()" @click="getIntentClientSecret">PRENOTA!</b-button>-->

                                          <div v-if="!loading">
                                            <b-button v-if="creditCardAmount <= 0" class="btn btn-block btn-success" :disabled="!canFinallyCheckout()" @click="checkoutConfirm">PRENOTA!</b-button>
                                            <b-button v-else class="btn btn-block btn-success" :disabled="!canFinallyCheckout()" @click="getIntentClientSecret">PRENOTA!</b-button>
                                          </div>

                                          <Spinner v-bind:msg="'Prenotazione in corso... non abbandonare o ricaricare la pagina!'" v-else/>


                                        </b-form-group>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>

                    </div>
                    

                    <!--<Stripe v-on:stripe-ok="stripeOk" />-->

                    <!-- confirm only -->
                    <div v-if="this.$route.name == 'confirm'">
                        <a href="/" class="btn btn-block btn-secondary">Torna alla Home</a>
                        <!--
                        TODO rendere contestuale
                        <br>
                        <a href="#" class="btn btn-block btn-secondary">Torna a Easy Welfare</a>-->
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Api from '../../../api'
import CheckoutBillToPax from './checkout-bill-to-pax' /* SIDEBAR REFACTOR */
import CheckoutPax from './checkout-pax' /* SIDEBAR REFACTOR */
import Stripe from '../stripe'
import SvgInfoCircle from './../../svg/info-circle'
import SvgCreditCard from './../../svg/credit-card'
import TicketIcon from './../../svg/ticket-icon'
import SvgChevronDown from './../../svg/chevron-down'
//import SvgEdenredEtcMainButtonOutline from './../../svg/edenred-etc-main-button-outline'
import Spinner from './../../atoms/spinner'
import BtnBack from './../../atoms/btn-back'
import CustomSwitch from './../../atoms/custom-switch'
import ResumePaymentEtc from './resume-payment-etc'
import {number, larger, smaller, smallerEq, round, string, subtract} from "mathjs";

export default {
    name: 'sidebar-etc',
    components:
    {
        Spinner,
        SvgInfoCircle, SvgCreditCard, TicketIcon, SvgChevronDown, //SvgEdenredEtcMainButtonOutline,
        BtnBack,
        CustomSwitch,
        Stripe,
        ResumePaymentEtc,
        CheckoutPax, /* SIDEBAR REFACTOR */
        CheckoutBillToPax, /* SIDEBAR REFACTOR */
    },
    props:
    {
        //bookingData: Object
    },
    computed: {
        availability: {
            get() { return this.$store.state.integration.availability },
            set(value) { this.mixinSendMutation('setAvailability',value) }
        },
        token: {
            get() { return this.$store.state.token },
            set(value) { this.mixinSendMutation('setToken',value) }
        },
        bookingData: {
            get() { return this.$store.state.bookingData },
            set(value) { this.mixinSendMutation('setBookingData',value) }
        },
        affiliateReferenceId: {
            get() { return this.$store.state.affiliateReferenceId },
            set(value) { this.mixinSendMutation('setAffiliateReferenceId',value) }
        },
        /*
        validFeedback() {
            if (this.etcCollapseOpen) return 'ko'
            if (this.etcAmount > this.checkoutAmount) return 'ko'
            if (this.loadingEtc) return 'ko'
            if (this.guestEmail.length < 4) return 'ko'
            if (this.guestEmail !== this.guestEmailConf) return 'ko'
            if (this.guestPhone.length < 4) return 'ko'
            if (!this.guestPrivacy) return 'ko'
            //if (!this.beneficiaryId) return 'ko'
            if (this.guestFirstName.length < 4) return 'ko'
            if (this.guestLastName.length < 4) return 'ko'
            //if (!this.stripeInfoCheck) return 'ko'
            if (!this.validEmail(this.guestEmail)) return 'ko' // regex, alla fine
            return 'ok'
        },
        */
        geo: {
          get() { return this.$store.state.geo },
          set() {}
        },
    },
    data() {        
        return {
            checkoutStatusUserDataVisible: false,
            checkoutStatusPaymentMethodsVisible: true,
            checkoutStatusConfirmVisible: false,

            checkoutStatusUserDataOk: false,
            checkoutStatusPaymentMethodsOk: false,
            //checkoutStatusConfirmOk: false,

            etcAutoSelect: false,
            calculating: false,
            canUseEtc: false,
            checkoutAmount: 0,
            creditCardAmount: 0,
            etcAmount: 0,
            //ccCollapseOpen: false,
            etcCollapseOpen: true,
            stripeInfoCheck: false,
            status: 'not_accepted',
            displayName: Vue.prototype.$config.integration.displayName,
            avatarSrc: Vue.prototype.$config.integration.avatarSrc,
            guestGdpr: (process.env.VUE_APP_MODE == 'development')? true : false,
            disclaimerCheckout: Vue.prototype.$config.guiSettings.disclaimerCheckout,
            site: Vue.prototype.$config.site,
            loading: false,
            loadingEtc: false,
            guestFirstName: (process.env.VUE_APP_MODE == 'development')? 'Pinco' : '',
            guestLastName: (process.env.VUE_APP_MODE == 'development')? 'Pallo' : '',
            guestEmail: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            guestEmailConf: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            guestPhone: (process.env.VUE_APP_MODE == 'development')? '3934288350' : '',
            guestPrivacy: (process.env.VUE_APP_MODE == 'development')? true : false,
            //beneficiaryId: '',
            //beneficiaries: [],
            vouchers: [],
            fees: 0,

            /* SIDEBAR REFACTOR: oggetto che conterrà tutti i sottoblocchi della sidebar richiesti per il checkout (dati beneficiario, dc, etc, billToPax, ecc) */
            sidebarBlocks: {
                checkoutPax: {
                    initialize: true,       // per inizializzarlo o meno nel template; è il valore che viene controllato anche per prelevare i campi prima della conferma
                    status: false,          // tiene traccia della validazione riuscita o meno dei campi
                    fields: {}              // campi che vengono eventualmente inviati a bookingData
                },
                checkoutBillToPax: {
                    initialize: this.mixinCanBillToPax(),
                    status: false,
                    fields: {}
                }
            }
        }
    },
    beforeMount()
    {
        //window.console.log('before mount')
    },
    mounted()
    {

        let _this = this

        _this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            if(collapseId == 'checkoutStatusUserData')
            {
                // apertura cassetto
                if(isJustShown)
                {
                    _this.etcCollapseOpen = false
                }
                /*
                // chiusura cassetto
                else
                {

                }
                */
            }
            if(collapseId == 'accordion-etc')
            {
                // apertura cassetto etc
                if(!isJustShown)
                {
                    if(larger(number(_this.etcAmount), number(_this.checkoutAmount)))
                    {
                        _this.etcReset()
                    }
                }/*
                // chiusura cassetto etc
                else
                {

                }*/
            }
        })

        _this.fees = _this.bookingData.totals.mandatory_tax + _this.bookingData.totals.mandatory_fee + _this.bookingData.totals.resort_fee
        _this.checkoutAmount = _this.bookingData.live_price
        _this.creditCardAmount = _this.bookingData.live_price
        _this.etcAmount = 0

        //if(_this.token && _this.$route.name == 'checkout')
        //{
            _this.loadingEtc = true

            let getBalancesPayload = { token: _this.token }

            /*if(process.env.VUE_APP_MODE == 'development'){
              // vouchers per test interfaccia, non sarà possibile concludere la prenotazione
              getBalancesPayload.vouchers = [
                {count:10, value: "20.17"},
                {count:15, value:"10.23"},
                {count:5, value:"30.33"},
                {count:10, value:"200"},
              ]
            }*/

            Promise.all([
                /*
                Api.getBeneficiary({
                    token: _this.token
                }),
                */
                Api.getBalances(getBalancesPayload)
            ])
            .then(promises => {
                /*
                let bens = Object.values(promises[0].beneficiaries)
                bens.forEach(ben => {
                    _this.beneficiaries.push({
                        value: ben.id,
                        name: ben.name,
                        surname: ben.surname,
                        fiscalCode: ben.fiscalCode,
                        text: ben.name + ' ' + ben.surname
                    })
                })
                _this.beneficiaryId = (_this.beneficiaries.length !== 0 ? _this.beneficiaries[0].value : 0)
                */

                _this.availability = promises[0].availability
                _this.vouchers = promises[0].vouchers
                
                _this.etcReset()

                if(_this.vouchers.length > 0)
                {
                    _this.canUseEtc = ( smallerEq(number(_this.vouchers[0].value), number(_this.checkoutAmount)) )
                }

                if(_this.$config.integration.availability !== _this.availability)
                {
                    _this.mixinSendMutation('setAvailability', _this.availability)
                }
    
                _this.loadingEtc = false
            })
        //}
    },
    methods:
    {
      number,
      larger,
      smallerEq,
      round,
      string,
      subtract,
      smaller,
        /* SIDEBAR REFACTOR: metodo che dovrà gestire la validazione del cassetto aperto della sidebar, momentaneamente targettizzato solo su 'billToPax', in futuro astratto */
        sidebarBlockChange(e){
            let _this = this
            _this.sidebarBlocks[e.name].fields = e.fields
            _this.sidebarBlocks[e.name].status = e.status
            this.getUserDataStatus()
        },        
        etcReset()
        {
            this.vouchers.forEach((v,index) => {
                this.vouchers[index].used = 0,
                this.vouchers[index].amount = 0
            })
            this.vouchers.sort(function(a, b) {
                return a.value - b.value;
            })
            this.etcAutoSelect = false
            this.updateTotals()            
        },
        etcAutoSelectCalculation(e)
        {
            if(window.LogRocket) window.LogRocket.track('etc_autoSelectCalculation')
            if(window.clarity) window.clarity("set", "etc", "autoSelectCalculation")

            if(e == false)
            {
                this.etcAutoSelect = false
                this.etcReset()
                return
            }

            this.etcAutoSelect = true
            let residual = this.checkoutAmount

            for(let v=this.vouchers.length-1; v >= 0; v--)
            {
                let voucher = this.vouchers[v],
                    //amount = voucher.amount,
                    count = voucher.count,
                    value = number(voucher.value)
                    //used = voucher.used

                this.vouchers[v].used = Math.min(Math.floor(residual / value),count)
                this.vouchers[v].amount = this.vouchers[v].used * this.vouchers[v].value

                residual = residual - this.vouchers[v].amount
            }
            this.updateTotals()
        },
        addVoucher(index)
        {
            if(!this.canUseEtc) return

            this.etcAutoSelect = false
            this.vouchers[index].used = this.vouchers[index].used + 1
            this.vouchers[index].amount = (this.vouchers[index].used)*(this.vouchers[index].value)
            this.updateTotals()
        },
        removeVoucher(index)
        {
            if(!this.canUseEtc) return

            this.etcAutoSelect = false
            this.vouchers[index].used = this.vouchers[index].used - 1
            this.vouchers[index].amount = (this.vouchers[index].used)*(this.vouchers[index].value)
            this.updateTotals()
        },
        updateTotals()
        {
            this.calculating = true
            let etcAmount = 0
            this.vouchers.forEach(voucher => {
                etcAmount += number(voucher.amount)
            });
            this.etcAmount = round(etcAmount, 2)
            this.creditCardAmount = Math.max(round(subtract(number(this.checkoutAmount), number(this.etcAmount)), 2), 0)
            this.calculating = false
        },
        getIntentClientSecret()
        {
            if(!this.stripeInfoCheck)
            {
                //this.ccCollapseOpen = true
                return
            }
            
            this.loading = true

            // paga solo con ticket compliments
            if(this.etcAmount == this.checkoutAmount)
            {
                this.checkoutConfirm()
                return;
            }

            // paga con carta di credito e, opzionalmente, con ticket compliments
            let stripeName = this.$refs.stripe.$refs.ccname.value
            if(stripeName == '')
            {
                this.loading = false
                //this.ccCollapseOpen = true
                this.etcCollapseOpen = false
                return
            }
            this.$refs.stripe.getIntentClientSecret()
        },
        stripeChange(e)
        {
            this.stripeInfoCheck = e
        },
        stripeConfirm(e)
        {
            if(e.error)
            {
                this.loading = false
                let data = {
                    msg: e.message,
                    status: e.status,
                    callback: function() {}
                }
                //data.title = ''
                //data.closeText = ''
                data.msg = e.result.message
                window.eventBus.$emit('gotNotification',data)
                return
            }
            this.bookingData.payments.cc.paymentIntentId = e.paymentIntentId
            this.bookingData.payments.cc.nameSurname = e.name
            this.checkoutConfirm()
        },
        checkoutConfirm()
        {

            this.loading = true

            this.bookingData.payments.cc.amount = String(this.creditCardAmount)
            //this.bookingData.payments.cc.nameSurname = this.nameSurname
            this.bookingData.payments.etc.amount = String(this.etcAmount)

            this.bookingData.payments.etc.vouchers = []

            if(this.etcAmount !== 0)
            {
                this.vouchers.forEach(voucher => {
                    if(voucher.used !== 0)
                    {
                        (this.bookingData.payments.etc.vouchers).push({
                            count: voucher.used,
                            value: voucher.value
                        })
                    }
                });
            }

            /*this.bookingData.payments.etc.vouchers.forEach(function(v){
                delete v.amount
                delete v.used
            })*/

            let _this = this,
                parameters = {},
                bookingData = Object.assign({},_this.bookingData)
            
            /*parameters.bookingData = bookingData
            parameters.token = _this.token
            parameters.guestEmail = _this.guestEmail
            parameters.guestEmailConf = _this.guestEmailConf
            parameters.guestPhone = _this.guestPhone
            parameters.guestPrivacy = _this.guestPrivacy
            parameters.beneficiaryId = 0
            parameters.guestFirstName = _this.guestFirstName
            parameters.guestLastName = _this.guestLastName*/

            // TODO verificare
            parameters.bookingData = bookingData
            parameters.token = _this.token
            parameters.guestEmail = _this.sidebarBlocks.checkoutPax.fields.guestEmail
            parameters.guestEmailConf = _this.sidebarBlocks.checkoutPax.fields.guestEmailConf
            parameters.guestPhone = _this.sidebarBlocks.checkoutPax.fields.guestPhone
            parameters.guestPrivacy = _this.guestPrivacy
            parameters.guestGdpr = _this.guestGdpr
            parameters.beneficiaryId = 0
            parameters.guestFirstName = _this.sidebarBlocks.checkoutPax.fields.guestFirstName
            parameters.guestLastName = _this.sidebarBlocks.checkoutPax.fields.guestLastName

            
            /* SIDEBAR REFACTOR: serie di if che popoleranno bookingData con i set di campi che possono o meno comparire a seconda di come è stata costruita la sidebar */
            if(_this.sidebarBlocks.checkoutBillToPax.initialize) //non controllo lo status perché se è false non dovrei essere arrivato alla conferma
            {
                parameters.guestAddress = _this.sidebarBlocks.checkoutBillToPax.fields.guestAddress
                parameters.guestCity = _this.sidebarBlocks.checkoutBillToPax.fields.guestCity
                parameters.guestPostalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestPostalCode
                parameters.guestProvince = _this.sidebarBlocks.checkoutBillToPax.fields.guestProvince
                parameters.guestFiscalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestFiscalCode
            }
            
            Api
                .checkoutLivebooking(parameters)
                .then((response) => {


                    if(response.status == 300)
                    {
                        _this.mixinSendMutation('setAffiliateReferenceId',response.data.retryWithAffiliateReferenceId)
                        _this.bookingData.affiliateReferenceId = response.data.retryWithAffiliateReferenceId
                        return _this.checkoutConfirm()
                    }

                    _this.loading = false

                    if(response.status == 200)
                    {

                        if(_this.$gtm){

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'userBehaviour',
                            action: 'confirmedCheckout',
                            //label: Vue.prototype.$config.integration.email,
                            value: _this.bookingData.live_price,
                          });

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'partnerBehaviour',
                            action: 'confirmedCheckout',
                            label: Vue.prototype.$config.integration.company,
                            value: _this.bookingData.live_price,
                          });


                          let eventGeoExtraValue = (_this.geo.matchLevel == 'inSalabam')
                              ? _this.geo.inSalabamId
                              : (_this.geo.matchLevel == 'property')
                                  ? _this.geo.propertyId
                                  : 0;

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'confirmedCheckoutSearch',
                            action: _this.geo.matchLevel,
                            label: _this.geo.label,
                            value: eventGeoExtraValue,
                          });


                        }


                        if(window.LogRocket) window.LogRocket.track('confirmedCheckout')
                        if(window.clarity) window.clarity("set", "checkout", "confirmed")

                        this.$emit('sidebar-order-confirmed', {})

                    _this.mixinSendMutation('setAvailability',response.data.updateAvailabilityTo)
                        _this.$router.push({
                            name: 'confirm',
                            params: {
                                confirmcode: response.data.confirmcode,
                                itinerary_id: response.data.itinerary_id,
                                newAffiliateReferenceId: response.data.newAffiliateReferenceId,
                                redirectIfConfirmedRedirectTo: true
                            }
                        })
                    }
                })
        },
        validEmail(email){
            return /\S+@\S+\.\S+/.test(email)
        },

        getUserDataStatus()
        {
            /* SIDEBAR REFACTOR */
            if(this.sidebarBlocks.checkoutPax.initialize)
            {
                if(this.sidebarBlocks.checkoutPax.status == false) return false
            }

            /* SIDEBAR REFACTOR */
            if(this.sidebarBlocks.checkoutBillToPax.initialize)
            {
                if(this.sidebarBlocks.checkoutBillToPax.status == false) return false
            }

            /*
            if (this.guestEmail.length < 4) return false
            if (this.guestEmail !== this.guestEmailConf) return false
            if (this.guestPhone.length < 4) return false  
            if (this.guestFirstName.length < 4) return false
            if (this.guestLastName.length < 4) return false
            if (!this.validEmail(this.guestEmail)) return false // regex, alla fine
            */
            return true
        },
        getPaymentMethodsStatus()
        {
            if (!this.getUserDataStatus()) return false
            if (this.loadingEtc) return false
            if (!this.canUseEtc) return true
            if (this.etcAmount > this.checkoutAmount) return false
            return true
        },
        canFinallyCheckout()
        {
            if (!this.getUserDataStatus()) return false           // ridondante, per sicurezza
            if (!this.getPaymentMethodsStatus()) return false     // ridondante, per sicurezza
            if (this.creditCardAmount > 0 && !this.stripeInfoCheck) return false
            if (!this.guestPrivacy) return false
            if (!this.guestGdpr) return false
            return true
        }
    }  
}
</script>

<style lang="scss">
    
    
    
    

    #sidebar-etc
    {
        input, select, .form-control
        {
            border:1px solid $smooth-black;
            margin-bottom:0;//$grid-gutter-width/2;
            font-weight:400;
            box-shadow: none;
        }

        .checkoutStatusButtons
        {
            display:flex;
            flex-direction:column;
            //margin:$grid-gutter-width/2 0;
            button:not(.btn-sm)
            {
                margin:0 auto;
                max-width:420px;
                width:100%;
                //max-width: calc(100% - #{$grid-gutter-width});
                &:not(:last-child)
                {
                    margin-bottom:12px;
                }
            }
        }

        button
        {
            //font-weight:600;
            //font-size: 20px;
            box-shadow:none;
        }

        .btn-sm
        {
            min-width:140px;
        }

        label
        {
            font-weight:400;
            margin-bottom:4px;
        }
        
        div[role=tablist]
        {
            display:block;
            width:100%;
        }

        .togglePayment
        {
            width:100%;
            display:flex;
            justify-content: space-between;
            align-items:center;
            text-align:left;
            //@include customize-color("secondary");
            font-weight: $font-weight-normal;
            font-size: 16px;
            cursor:pointer;
            svg
            {
                margin-right:6px;
                margin-bottom:2px;
            }
            .chevron
            {
                width:22px;
                margin-right:30px;
                path
                {
                    //@include customize-stroke("secondary");
                }
            }
            &.black
            {
                color:$black;
                svg path
                {
                    stroke:$black;
                    fill:$black;
                }                
            }
        }

        .panel-tertiary
        {
            .panel-title,
            .panel-content
            {
                color:$white;
                svg.svgInfo
                {
                    path
                    {
                        fill:$white;
                    }
                }
                svg.chevron
                {
                    path
                    {
                        stroke:$white;
                    }
                }
            }

            #checkoutStatusPaymentMethods
            {
                .togglePayment
                {
                    color:$white;
                    .d-flex 
                    {
                        color:$white;
                        svg path
                        {
                            fill:$white;
                        }
                    }
                }
            }

            p
            {
                margin-bottom:0;
                font-weight:normal;
            }

            .top
            {
                color:$smooth-black;
                padding:$grid-gutter-width/2;
                display:flex;
                align-items: center;
                padding-bottom:$grid-gutter-width/2;
                img {
                    width:44px;
                    height:44px;
                    margin-right:$grid-gutter-width/2;
                }
                p
                {
                    font-size:19px;
                }
                .name
                {
                    font-size: 16px;
                }
                .credit
                {
                    @include customize-color("secondary");
                    font-size: 14px;
                }
            }
            .panel-content
            {
                padding:$grid-gutter-width/2;
                color:$smooth-black;
            }
        }

        .panel-title
        {
            margin-bottom:0;
            &[aria-expanded]
            {
                cursor:pointer;
                text-align:left;
                display:flex;
                align-items:center;
                align-content:center;
                justify-content:space-between;
            }
            &[aria-expanded=true]
            {
                color:$white;
                svg path
                {
                    stroke:$white;
                }
            }
            //&+ .collapse,
            //&+ .collapsing,
            &+ *
            {
                margin-top:1.5rem;
            }
        }

        .checkoutDynamicBlockTitle
        {
            display:flex;
            align-items:center;
            text-align:left;
            font-size:16px;
            @include media-breakpoint-up(lg)
            {
                font-size:19px;
            }
            .stepNumber
            {
                width:30px;
                height:30px;
                border-radius:50%;
                background:rgba($black,0.095);
                text-align:center;
                font-size: 75%;//14px;
                margin-right:12px;
                display:flex;
                align-items:center;
                justify-content:center;
            }
        }

        .panel-content:not(:last-child)
        {
            border-bottom:1px solid $white;
        }

        .privacy-link
        {
            @include customize-color("cta");
            text-decoration:underline;
            font-size: 13px;
        }

        .checkout-resume-block
        {
            font-size: 14px;
            font-weight:400;

            .checkout-resume-row
            {
                display:flex;
                justify-content:space-between;
                align-items: center;

                & > span {
                    margin-bottom: 10px;
                    
                    &:first-child {
                        flex-basis: 60%;
                    }
                    &:last-child {
                        flex-basis: 40%;
                        text-align: right;
                        font-weight: 500;
                    }
                }
            }

            .checkoutPax, .checkoutBillToPax {
                .checkout-resume-row {
                    margin-bottom: 15px;
                }
            }

            strong
            {
                font-size: 16px;
            }
            small
            {
                display:block;
                //margin-top:8px;
            }
            svg.svgInfo
            {
                width: 18px;
                margin-left: 3px;
                margin-right: 10px;
                margin-top: 0;
            }
            &:not(:last-child)
            {
                border-bottom:1px solid rgba($white,0.65);
                margin-bottom:0.75rem;
                padding-bottom:0.75rem;
                small
                {
                    margin-bottom:8px;
                }
            }
        }

        strong.danger
        {
            margin:0.5rem auto;
            font-size:14px;
        }

        .danger
        {
            color:$danger;
            flex:100%;
            width:100%;
            min-width:100%;
            font-size:14px;
            cursor:pointer;
            svg
            {
                margin-right:4px;
                *
                {
                    fill:$danger;
                }
            }
        }

        #accordion-cc,
        #accordion-etc
        {
            display:block;
            margin-top:1rem;
        }

        #accordion-etc
        {
            &.show+.resumePayment,
            &.collapsing+.resumePayment
            {
                opacity:0;
            }
        }

        //#accordion-cc,
        //#accordion-etc
        //{
            .resumePayment //&:not(.show) + .resumePayment
            {
                justify-content: space-between;
                .value
                {
                    //text-align:right;
                    white-space:nowrap;
                    width:auto;
                    min-width:80px;
                    flex:0;
                    text-align:center;
                }
            }
        //}

        .logoEdenred
        {
            margin:1rem auto;
        }

        .cannotUseEtc
        {
            .resumePayment *,
            .vouchersTable *
            {
                color:$gray-600 !important;
            }
            .vouchersBlock
            {
                border-bottom:1px solid $gray-600 !important;
            }
            .vouchersTable svg
            {
                circle
                {
                    fill: $gray-600 !important;
                }
                path
                {
                    fill: $gray-500 !important;
                }
            }
        }

        .cannotUseEtcAlert
        {
            text-align:center;
            margin:1.25rem 0;
            .danger
            {
                display:block;
                text-transform: uppercase;
                margin-bottom:1rem;
            }
        }

        .vouchersTable
        {
            display:flex;
            flex-direction: column;
            text-align:center;
            .vouchersBlock
            {
                width:100%;
                min-width:100%;
            }
            .vouchersTableRow
            {
                border-bottom:1px solid #dedede;
                margin-bottom:1rem;
                padding-bottom:1rem;
                width:100%;
                display:flex;
                &>div
                {
                    width:33%;
                    min-width:33%;
                    flex:33%;
                }
            }
            strong
            {
                font-weight:700;
                color:#8C8C8C;
                font-size: 14px;
            }
            .voucherCount
            {
                color:$etcBlue;
                font-weight:600;
                font-size:20px;                
                .setVoucherIcon
                {
                    cursor:pointer;
                }
                display:flex;
                span
                {
                    width:33%;
                    min-width:33%;
                    flex:33%;
                }
            }
            .setVoucherIcon
            {
                font-weight:700;
                svg
                {
                    width:20px;
                    height:auto;
                    path
                    {
                        //fill:#E9EBF0;
                    }
                }
            }
        }

        #accordion-etc
        {
            .voucherTotal,
            .voucherValue,
            .voucherCount,
            .resumePayment
            {
                color:$etcBlue;
                font-weight:500;
                font-size:20px;
            }
            .voucherTotal,
            .resumePayment
            {
                font-weight:600;
            }
        }

        .etcLower
        {
            text-align:right;
            .etcAutoSelect
            {
                display:flex;
                align-items:center;
                justify-content: flex-end;
                margin-bottom:0.5rem;
            }
            .custom-switch
            {
                margin-left:1rem;
                label
                {
                    width:70px;
                    height:35px;
                    font-size:11px;
                    margin-bottom:0;
                }
            }
        }

        .resumePayment
        {
            .label
            {
                text-align:left;
                padding-left:24px;
            }
        }
        
        #checkout-form
        {
            width:100%;
            .btn
            {
                margin-top:2rem;
            }            
        }

        .privacy-box
        {
            display: flex;
            justify-content:center;
            align-items:center;
            min-width:100%;
            @include customize-color("cta");
            margin-top:1rem;
            input
            {
                //margin-top: 2px;
            }
            label
            {
                flex:1;
                margin-bottom:0;
            }
            /*.checkoutStatusButtons
            label[for=checkbox-privacy]
            {
                padding-left: 8px;
                line-height: 120%;
                margin-bottom:0;
            }
            */
        }

        @include media-breakpoint-up(xl)
        {
            .panel .top,
            .panel .bottom
            {
                padding:$grid-gutter-width/3*2;
            }
            .panel .top
            {
                .name
                {
                    font-size: 18px;
                }
                .credit
                {
                    font-size: 18px;
                }
            }
            .panel .panel-content
            {
                padding:$grid-gutter-width/3*2;
                font-size:16px;
            }
        }

        @include media-breakpoint-down(sm)
        {
            .panel-tertiary
            {
                //margin-left:$grid-gutter-width/2;
                //margin-right:$grid-gutter-width/2;
                width:auto;
            }
        }

        @include media-breakpoint-only(lg)
        {
            .panel-tertiary
            {
                margin-right:$grid-gutter-width/2;
            }
            .panel-title
            {
                font-size: 18px;
                margin-bottom:10px;
            }
            .checkout-resume-row span
            {
                font-size: 12px;
            }
            #checkout-form
            {
                label
                {
                    font-size: 14px;
                }
            }
        }
    }

    // orribile hack per mostrare la sidebar etc su mobile senza rifattorizzare tutto quanto
    @include media-breakpoint-down(sm)
    {
        #checkout
        {
            #main
            {
                display:flex;
                flex-direction: column;
                #content
                {
                    //order:2;
                }
                #sidebar
                {
                    display:block !important;
                    //order:1;
                }
                .sidebar-inner
                {
                    width:calc(100% - #{$grid-gutter-width});
                    margin:0 $grid-gutter-width/2;
                    margin-top:$grid-gutter-width/2;
                }
            }
        }

        
    }


        .sidebar-checkout-panel
        {
            p
            {
                margin-bottom:0;
                font-weight:normal;
            }

            .top
            {
                padding:$grid-gutter-width/2;
                display:flex;
                align-items: center;
                padding-bottom:$grid-gutter-width/2;
                border-bottom:1px solid $white;
                img {
                    width:44px;
                    height:44px;
                    margin-right:$grid-gutter-width/2;
                }
                p
                {
                    font-size:19px;
                }
                .name
                {
                    font-size: 16px;
                }
                .credit
                {
                    @include customize-color("secondary");
                    font-size: 14px;
                }
            }
            .panel-content
            {
                padding:$grid-gutter-width/2;
                color:$smooth-black;
                font-size:14px;
                color:$black;
                font-size:15px;
            }
        }

    .no-etc
    {
        background-color:transparent !important;
        border:0px !important;
        color:$black;
        font-weight:600;
        position:relative;
        &:before
        {
            content:"X";
            font-weight:500;
            margin-right:12px;
        }
        &:after
        {
            content:"";
            position:absolute;
            height:1px;
            left:28px;
            right:6px;
            bottom:2px;
            background:$black;
        }
    }


    //restyle vadobay
    .sidebar-checkout-panel
    {
        //@include customize-background-color("primary");
        //@include theme-gradient-horizontal();
        background-color:$white;
        border-radius: $border-radius-lg;
        .checkoutDynamicBlockTitle,
        .panel-title
        {
            @include customize-color("tertiary");
        }
        .chevron path
        {
            @include customize-stroke("tertiary");
        }
        .panel-content:not(:last-child),
        .checkout-resume-block:not(:last-child)
        {
            border-bottom-color:rgba($smooth-black,0.25) !important;
        }
    }
</style>